import _ from 'lodash'

import {
	namespace,
	AnimationsWixCodeSdkFactoryInitialState,
	AnimationsWixCodeSdkHandlers,
	AnimationsWixCodeSdkWixCodeApi,
	Timeline,
	TimeLineOptions,
} from '..'

const getCompIds = (target: any) => {
	// TODO
	console.log(target)
	return []
}
const buildAnimationParams = (animation: any) => {
	// TODO: copy from js-wixcode-namespaces
	console.log(animation)
	return []
}

const buildTimelineParams = (timelineOptions?: TimeLineOptions) => {
	// TODO: copy from js-wixcode-namespaces
	console.log(timelineOptions)
	return { repeat: 0, repeatDelay: 0, yoyo: false, paused: true }
}
export function AnimationsSdkFactory(
	__: AnimationsWixCodeSdkFactoryInitialState,
	handlers: AnimationsWixCodeSdkHandlers
): { [namespace]: AnimationsWixCodeSdkWixCodeApi } {
	const {
		createTimeline,
		addToTimeline,
		playTimeline,
		pauseTimeline,
		seekTimeline,
		reverseTimeline,
		onStartTimeline,
		onCompleteTimeline,
		onRepeatTimeline,
		onReverseCompleteTimeline,
	} = handlers
	return {
		[namespace]: {
			timeline: (timelineOptions?: TimeLineOptions) => {
				const id = _.uniqueId('timeline_')
				createTimeline(id, buildTimelineParams(timelineOptions))
				const timeline: Timeline = {
					// TODO: full implementation
					add: (target, animations, offset) => {
						const compIds = getCompIds(target)
						const params = buildAnimationParams(animations)
						addToTimeline(id, compIds, params, offset)
						return timeline
					},
					play: () => {
						playTimeline(id)
						return timeline
					},
					pause: () => {
						pauseTimeline(id)
						return timeline
					},
					replay: () => {
						seekTimeline(id, 0)
						playTimeline(id)
						return timeline
					},
					reverse: () => {
						reverseTimeline(id)
						return timeline
					},
					onStart: (handler) => {
						onStartTimeline(id, handler)
						return timeline
					},
					onComplete: (handler) => {
						onCompleteTimeline(id, handler)
						return timeline
					},
					onRepeat: (handler) => {
						onRepeatTimeline(id, handler)
						return timeline
					},
					onReverseComplete: (handler) => {
						onReverseCompleteTimeline(id, handler)
						return timeline
					},
				}

				return timeline
			},
		},
	}
}
